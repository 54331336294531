











import { computed, defineComponent, onMounted, ref, useContext, useRoute } from '@nuxtjs/composition-api';
import { eventBus } from "~/almarwan/helpers/EventBus";
import { useUiHelpers } from "~/composables";
export default defineComponent ({
  name: 'WhatsUp',
  setup() {
    const { app, app: { i18n } } = useContext();
    const route = useRoute();
    const { getFullUrl } = useUiHelpers()
    const whatsappIcon = ref(false);
    const promotext = i18n.t('whatsappOfferText')
    const whatsappUrl = computed(() => {
      const curUrl = getFullUrl(route.value.path);
      let whatsApptxt;
      if(route.value.path ===app.localePath('/kobelco-excavators-zero-down-payment')){
        whatsApptxt = i18n.t('whatsappOfferText')
      }else {
        whatsApptxt = curUrl.includes('/c/') || curUrl.includes('/p/') ? i18n.t("I need more info about this item ").toString() + curUrl : i18n.t("Hi, I'd like to learn about your current offers on heavy machinery").toString();
      }
      if(route.value.query?.text){
        if(typeof route.value.query?.text === 'string') {
          whatsApptxt = route.value.query?.text;
        } else {
          whatsApptxt = route.value.query?.text.join().replace(',', '?')
        }
      }
      return 'https://wa.me/971565433222?text='+whatsApptxt;
    });
    const ariaLabel = computed(() => {
      return route.value.path === app.localePath('/kobelco-excavators-zero-down-payment')
        ? i18n.t('whatsappOfferText').toString()
        :  i18n.t("Hi, I'd like to learn about your current offers on heavy machinery.").toString();
    });

    onMounted(() => {
      eventBus.$on('checkIfShowWhatsappIcon', (data) => {
        whatsappIcon.value = data
      });
    })
   return {
      whatsappUrl,
      whatsappIcon,
      promotext,
      route,
      ariaLabel
   }
  },
  // head() {
  //   return {
  //     script: [
  //       {
  //         hid: 'Tawkto',
  //         innerHTML: `
  //         var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
  //         (function(){
  //           var s1=document.createElement('script'),s0=document.getElementsByTagName('script')[0];
  //           s1.async=true;
  //           s1.src='https://embed.tawk.to/660da5941ec1082f04de938b/1hqiktjbq';
  //           s1.charset='UTF-8';
  //           s1.setAttribute('crossorigin','*');
  //           s0.parentNode.insertBefore(s1,s0);
  //         })();
  //         // Custom styling
  //         Tawk_API.customStyle = {
  //           visibility: {
  //             desktop: {
  //               position: 'br', // bottom-right
  //               xOffset: 15, // 15px away from right
  //               yOffset: 20 // 20px up from bottom
  //             },
  //             mobile: {
  //               position: 'br',
  //               xOffset: 15,
  //               yOffset: 90
  //             },
  //           }
  //         }
  //       `,
  //         type: 'text/javascript',
  //         charset: 'utf-8',
  //         body: true
  //       }
  //     ]
  //   }
  // }
});
