var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"recommended-section"},[_c('div',{staticClass:"slider-wrapper"},[_c('div',{staticClass:"row"},[_c('h3',[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),(_vm.loading)?[_c('div',{staticClass:"flex smartphone-only"},_vm._l((2),function(i){return _c('SkeletonLoader',{key:i,attrs:{"height":"420px","width":"225x","margin":"30px 20px 0 0"}})}),1),_vm._v(" "),_vm._l((5),function(i){return _c('div',{key:i,staticClass:"flex desktop-only",staticStyle:{"width":"100%","margin-top":"30px"}},[_c('SkeletonLoader',{attrs:{"width":"80px","height":"23px"}})],1)})]:[(_vm.products && _vm.products.length > 0 && _vm.sortedProducts)?_c('div',{staticClass:"recommended-products"},[_c('Carousel',{staticClass:"recommended-products-slider",attrs:{"items":3,"autoWidth":true,"margin":30,"nav":false,"dots":false,"resposnive":_vm.recommendedResponsive}},[_vm._l((_vm.products),function(product,index){return [(
                  product.available_for_tags &&
                  (product.available_for_tags[0].label == 'For Sale' ||
                    product.available_for_tags[0].label == 'For Rent')
                )?_c('div',{key:index,staticClass:"product-card product-card--sm"},[(
                    product.available_for_tags &&
                    (product.available_for_tags[0].label == 'For Sale' ||
                      product.available_for_tags[0].label == 'For Rent')
                  )?_c('nuxt-link',{attrs:{"to":_vm.localePath(
                      encodeURI(("/p/" + (product.sku) + "/" + (product.url_key) + ".html"))
                    )}},[_c('figure',{staticClass:"product-card__image"},[(_vm.rentOrBuyVal === 'rent')?_c('span',{staticClass:"label"},[_vm._v("2018 - "+_vm._s(_vm.getCurrentYear))]):_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.getProductLabel(product)))]),_vm._v(" "),_c('div',{staticClass:"toggle-btns-placeholder"},[_c('wishlist-guest',{attrs:{"product":product}})],1),_vm._v(" "),_c('nuxt-img',{attrs:{"src":product.thumbnail.url,"alt":product.thumbnail.label,"format":"webp","loading":"lazy","sizes":"sm:100vw md:50vw lg:400px","width":"320","height":"215"}})],1),_vm._v(" "),_c('h4',[_vm._v(_vm._s(_vm.productGetters.getName(product)))]),_vm._v(" "),_vm._l((product.techSpec),function(item,index){return _c('div',{key:index},[(index < 4 && product[item.value] != null)?_c('p',[(item.label.toLowerCase() === 'working hours')?[_vm._v("\n                        "+_vm._s(item.label)+" :\n                        "),_c('strong',[_vm._v(_vm._s(product[item.value] === 1 ||
                          product[item.value] > 15000
                            ? 'Upon request'
                            : _vm.numberWithCommas(product[item.value])))])]:(item.label.toLowerCase() === 'milage')?[_vm._v("\n                        "+_vm._s(item.label)+" :\n                        "),_c('strong',[_vm._v(_vm._s(product[item.value] === 1 ||
                          product[item.value] > 250000
                            ? 'Upon request'
                            : _vm.numberWithCommas(product[item.value]) + ' KM'))])]:(
                          item.label.toLowerCase() === 'certified operator'
                        )?[_vm._v("\n                        "+_vm._s(item.label)+" :\n                        "),_c('strong',[_vm._v(_vm._s(product[item.value] === 564
                            ? 'Included'
                            : 'On Request'))])]:(
                          item.label.toLowerCase() === 'operating weight'
                        )?[_vm._v("\n                        "+_vm._s(item.label)+" :\n                        "),_c('strong',[_vm._v(_vm._s(_vm.numberWithCommas(product[item.value]).slice(0, 30))+"\n                          "+_vm._s('KG')),(product[item.value].length > 30)?_c('span',[_vm._v("...")]):_vm._e()])]:[_vm._v("\n                        "+_vm._s(item.label)+" :\n                        "),_c('strong',[_vm._v(_vm._s(product[item.value].slice(0, 30))),(product[item.value].length > 30)?_c('span',[_vm._v("...")]):_vm._e()])]],2):_vm._e()])})],2):_vm._e()],1):_vm._e()]})],2)],1):_c('div',{staticClass:"no-products p-4"},[(!_vm.loading)?_c('p',[_vm._v("No products to display.")]):_vm._e()])]],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }