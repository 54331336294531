










import { defineComponent, onMounted, ref, useContext } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { NuxtError } from '@nuxt/types';
import { setupDirect } from '@testing-library/user-event/dist/types/setup/setup';

export default defineComponent({
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      required: true,
    },
  },
  head() {
    return {
      meta: [{ hid: 'robots' ,name: 'robots', content: 'noindex,nofollow' }]
    }
  },
  setup() {
    const {app, app:{i18n}} = useContext()
    const errorHeading = i18n.t("Sorry, the page you're looking for is missing");
    const errorMsg = i18n.t('Try going back to the');
    const errorOtherKeys = i18n.t('Try searching using other keywords');
    const errorUrl = i18n.t('Homepage');
    const errorHtml = `<div class="single-page single-page--404"><figure><img src="/images/global/no-results.svg" alt="No results"> </figure> <h2>${errorHeading}</h2><p> ${errorOtherKeys}</p><p> ${errorMsg} <a href=${app.localePath('/')}>${errorUrl}</a> .</p></div>`

    return {
      errorHeading,
      errorMsg,
      errorUrl,
      errorOtherKeys,
      errorHtml
    }
  }
});
