

































































































































































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import productGetters from '../../../../modules/catalog/product/getters/productGetters';
import type { Product } from '../../../../modules/catalog/product/types';
import useUiHelpers from '../../../../composables/useUiHelpers';
import SkeletonLoader from '../../../../components/SkeletonLoader/index.vue';
import WishlistGuest from '../../../../components/General/WishlistGuest.vue';

export default defineComponent({
  name: 'ProductsCarousel',
  components: {
    WishlistGuest,
    Carousel:
      typeof window !== 'undefined' ? () => import('vue-owl-carousel') : null,
    SkeletonLoader,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    products: {
      type: Array as PropType<Product[]>,
      required: false,
      default: () => [],
    },
    loading: Boolean,
    rentOrBuyVal: {
      type: String,
      default: 'rent',
    },
  },
  data() {
    return {
      recommendedResponsive: {
        0: {
            items: 1,
            margin: 18,
            center: true,
          },
      }
    };
  },
  setup() {
    const { numberWithCommas, getCurrentYear } = useUiHelpers();
    
    return {
      numberWithCommas,
      productGetters,
      getCurrentYear,
    };
  },
  computed: {
    sortedProducts(): number | null {
      return this.products.filter(
        (product) => product['available_for_tags']?.[0]?.label === 'For Sale' || product['available_for_tags']?.[0]?.label === 'For Rent'
      ).length;
    },
  },
  methods: {
    getProductLabel(product) {
      return {
        '621': 'Used',
        '620': 'Like New',
        '619': 'New',
      }[product.condition] || null;
    },
  },
});
