




























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import useUser from '../../modules/customer/composables/useUser';
import { eventBus } from '../../almarwan/helpers/EventBus';
import useWishlist from '../../modules/wishlist/composables/useWishlist';
import { Product } from '../../modules/catalog/product/types';

export default defineComponent({
  name: 'WishlistGuest',
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
  },
  data() {
    return {
      isProductInWishlist: false,
      wishListForGuestUser: [],
    };
  },
  setup() {
    const { isAuthenticated } = useUser();
    const { addOrRemoveItem, isInWishlist, load } = useWishlist();

    return {
      isAuthenticated,
      addOrRemoveItem,
      isInWishlist,
      load,
    };
  },
  async mounted() {
    this.wishListForGuestUser = JSON.parse(
      localStorage.getItem('wishListForGuestUser') || '[]'
    );
    this.updateProductWishListStatus();
  },
  methods: {
    async updateProductWishListStatus() {
      if (this.isAuthenticated) {
        await this.load();
        const inWishlist = this.isInWishlist({ product: this.product });
        this.isProductInWishlist = inWishlist;
        return;
      }
      const productInGuestList = this.wishListForGuestUser.find(
        (prod) => prod.id === this.product.id
      );
      this.isProductInWishlist = !!productInGuestList;
    },
    updateWishlist() {
      if (this.isAuthenticated) {
        this.addOrRemoveItem({ product: this.product });
        this.updateProductWishListStatus();
        return;
      }
      
      const guestWishList: Product[] = JSON.parse(
        localStorage.getItem('wishListForGuestUser') || '[]'
      );
      const index = guestWishList.findIndex(
        (product) => product.id === this.product.id
      );

      if (index > -1) {
        guestWishList.splice(index, 1);
      } else {
        guestWishList.push(this.product);
      }
      this.wishListForGuestUser = guestWishList;
      localStorage.setItem(
        'wishListForGuestUser',
        JSON.stringify(guestWishList)
      );
      this.updateProductWishListStatus();
      eventBus.$emit('updateWishlistInAppHeader');
    },
  },
});
