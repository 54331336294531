






























































import { defineComponent, onMounted, ref, } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import {useUiState} from '~/composables';
import AppHeader from '~/components/Header/AppHeader.vue';
import BottomNavigation from '~/components/BottomNavigation.vue';
import WhatsUp from '@/almarwan/components/WhatsUp.vue'
import {eventBus} from "~/almarwan/helpers/EventBus";
import NotificationBar from "~/components/Notification.vue";
import Nav from "~/almarwan/layout/Nav.vue";
import AppFooter from "~/components/AppFooter.vue";
import CartSidebar from "~/components/CartSidebar.vue";
import WishlistSidebar from "~/modules/wishlist/components/WishlistSidebar.vue";
import LoginModal from "~/modules/customer/components/LoginModal/LoginModal.vue";
import MailingListModal from "~/modules/customer/components/MailingListModal/MailingListModal.vue";
import MakeOfferModal from "~/modules/catalog/category/components/views/MakeOfferModal.vue";
import CompareProductModel from "~/modules/catalog/category/components/views/CompareProductModel.vue";
import CompareProductsDetailModel from "~/modules/catalog/category/components/views/compareProductsDetailModel.vue";
import similarItemsModal from "~/modules/catalog/category/components/views/SimilarItemsModal.vue";
import CookieModel from '~/modules/customer/components/LoginModal/CookieModel.vue'

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    WhatsUp,
    LazyHydrate,
    AppHeader,
    BottomNavigation,
    NotificationBar,
    Nav,
    AppFooter,
    CartSidebar,
    WishlistSidebar,
    LoginModal,
    MailingListModal,
    MakeOfferModal,
    CompareProductModel,
    CompareProductsDetailModel,
    similarItemsModal,
    CookieModel
  },
  methods: {
    hendleToggle() {
      this.toggleVal = false;
    },
  },
  setup() {
    const checkIfPromoIsClosed = ref(true)
    const toggleVal = ref(false)
    const {
      isCartSidebarOpen,
      isCompareProductModalOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      toggleCookieModal,
      isMailingListModalOpen,
      toggleMailingListModal,
      isMakeOfferModalOpen,
      toggleMakeOfferModal,
      toggleCompareProductModal,
      toggleCompareProductDetailModal,
      isCompareProductDetailModalOpen,
      isSimilarItemsModalOpen,
    } = useUiState();

    onMounted(() => {

      eventBus.$on('promoPopup', (status) => {
        checkIfPromoIsClosed.value = status
      })
      const layout = document.querySelector('#layout');

      layout.addEventListener('click', () => {
        toggleVal.value = true;
      });
      window.addEventListener('scroll', () => {
        toggleVal.value = true;
      });
    })
    return {
      checkIfPromoIsClosed,
      toggleCompareProductDetailModal,
      isCompareProductDetailModalOpen,
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      toggleCookieModal,
      isMailingListModalOpen,
      isMakeOfferModalOpen,
      isCompareProductModalOpen,
      toggleMailingListModal,
      toggleMakeOfferModal,
      toggleCompareProductModal,
      isSimilarItemsModalOpen,
      toggleVal,
      isActive: false
    };
  },
});
