






import { computed, defineComponent, ref, toRef, useFetch } from '@nuxtjs/composition-api';

export default defineComponent({
  name:'ProductTag',
  props: {
    product: {
      type: Object,
      required: true
    },
    labelTag: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const product = toRef(props, 'product');
    const availableForTags = computed(() => product && product.value.available_for_tags)
    return {
      availableForTags,
    }
  }
})
